import styled from 'styled-components'
import { Link } from 'gatsby'

const GoBackLink = styled(Link)`
  font-size: 1.25em;
  cursor: pointer;
  width: 110px;
  height: 55px;
  border-radius: 27.5px;
  background-color: #eeeeee;
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: space-between;
  padding: 0 0.75rem;
  margin-left: 20px;
  text-decoration: none;
  color: black;

  .back-btn-icon {
    width: 40px;
    height: 40px;
  }
`

export default GoBackLink
