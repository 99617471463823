import React from 'react'
import styled from 'styled-components'
import { useIntl } from 'gatsby-plugin-intl'
import { Link } from 'gatsby'
import { Helmet } from 'react-helmet'
import * as dayjs from 'dayjs'

import Layout from '../../components/common/layout'
import Container from '../../components/common/container'
import GoBackLink from './goBackLink'
import CategoryArticleTitle from './categoryArticleTitle'
import CategoryArticle from './categoryArticle'
import PostContainer from './postContainer'

const CategoryContainer = styled.div`
  .blogs-list {
    padding: 1.5em 0;
    overflow: hidden;
    background: $white;
    color: #000;
  }

  .blogs-list-container {
    max-width: 1090px;
    margin: auto;
    margin-bottom: 40px;

    ${CategoryArticleTitle} {
      margin-top: 27px;
      margin-bottom: 20px;
    }

    ${GoBackLink} {
      margin: 0;
    }

    ${CategoryArticle} {
      margin-top: 0;
      flex-wrap: wrap;
      display: flex;
    }

    ${PostContainer} {
      margin-bottom: 40px;

      @media (max-width: 1100px) {
        margin-bottom: 0px;
      }
    }
  }
`

const BlogCategory = ({
  pageContext: { articles, categorySlug, categoryName },
}) => {
  const intl = useIntl()

  return (
    <Layout>
      <Container>
        <CategoryContainer>
          <div className='blogs-list'>
            <div className='blogs-list-container'>
              <GoBackLink className='back-btn' to={`/${intl.locale}/blog/`}>
                <img
                  className='back-btn-icon'
                  src='https://kto-static.mobilt.io/media/btn-back.svg'
                  alt='Back button'
                />
                {intl.formatMessage({ id: 'common.back' })}
              </GoBackLink>

              <CategoryArticleTitle>
                <div>
                  <h1>{categoryName}</h1>
                </div>
              </CategoryArticleTitle>

              <CategoryArticle>
                {articles.map((article, index) => (
                  <PostContainer key={index}>
                    <div className='blog-img-container'>
                      <Link to={`${article.slug}`}>
                        <img
                          className='blog-img'
                          src={`https://cdnblog.kto.com/wp-content/uploads/${article.featured_image}`}
                          alt={`${article.title}`}
                        />
                      </Link>
                    </div>

                    <div className='description-container'>
                      <Link className='description' to={`${article.slug}`}>
                        {article.title.length > 50
                          ? article.title.substr(0, 50) + '...'
                          : article.title}
                      </Link>
                      <h3 className='date'>
                        {dayjs(article.date_created).format('DD.MM.YYYY')}
                      </h3>
                    </div>
                  </PostContainer>
                ))}
              </CategoryArticle>
            </div>
          </div>
        </CategoryContainer>
      </Container>
      <Helmet>
        <title>{`${intl.formatMessage({
          id: 'seo.titleBranding',
        })} ${categoryName}`}</title>
      </Helmet>
    </Layout>
  )
}

export default BlogCategory
